import React, { useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  CardText,
  CardHeader,
  Container,
  Jumbotron,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import axios from "axios";

import "./App.css";

import config from "./config";

function App() {
  const isDebug = false;
  /**
   * server list schema server list schema
   * [
   *  {
   *    "name": "string",
   *    "status": "string",
   *    "ip": "string",
   *    "port": "string"
   *  }
   * ]
   */
  const [serverList, setServerList] = useState([]);
  const [actionStatus, setActionStatus] = useState("Get List");
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const onAlertDismiss = () => setAlertVisible(false);
  const onAlertEnable = (message) => {
    if (message === "") {
      return;
    }
    setAlertMessage(message);
    setAlertVisible(true);
    const timer = setTimeout(() => {
      onAlertDismiss();
    }, 3000);
    return () => clearTimeout(timer)
  };

  axios.interceptors.response.use(
    (response) => {
      console.log("Response was received");
      return response;
    },
    (error) => {
      // window.location.href = config.redirect_url;
      return Promise.reject(error);
    }
  );

  const listServers = async (event) => {
    return await startStopServer("list", "", "");
  };

  const startStopServer = async (action, serverSelected, message) => {
    const keyInput = document.getElementById("key_input");
    const key = keyInput.value;

    if (!key || key === "") {
      onAlertEnable("You didn't enter a password.");
      return;
    }

    setActionStatus("Loading...");

    const payload = {
      key: key,
      server: serverSelected,
      action: action,
    };

    const result = await axios({
      method: "POST",
      url: `${config.api_base_url}/manage`,
      data: payload,
    }).catch((reason) => {
      setActionStatus("Get List");
      onAlertEnable(
        "Loading failed, did you enter the correct password? " + reason
      );
    });

    if (result && (result.status === 401 || result.status === 404)) {
      setActionStatus("Get List");
      onAlertEnable("Loading failed, did you enter the correct password?");
    } else if (result && result.status === 200) {
      console.log("success");
      if (action === "list") {
        const list = result.data;
        console.log(`list of servers: ${list}`);
        setServerList(list.list);
      }
      setActionStatus("Refresh");
      onAlertEnable(message);
      if (action === "start" || action === "stop") {
        // pollForStatus(key, serverSelected, action);
        const timer = setTimeout(() => {
          startStopServer("list", "meh", "");
        }, 5000);
        return () => clearTimeout(timer);
      }
    }
  };

  // const pollForStatus = async (key, name, action, attempt = 1) => {
  //   console.log(`attempt: ${attempt}`);
  //   if (attempt > 4) {
  //     return;
  //   }
  //   attempt += 1;
  //   const payload = {
  //     key: key,
  //     server: "meh",
  //     action: "list",
  //   };
  //   const result = await axios({
  //     method: "POST",
  //     url: `${config.api_base_url}/manage`,
  //     data: payload,
  //   });

  //   if (result.status === 200) {
  //     result.data.list.forEach((element) => {
  //       if (element.name !== name) {
  //         if (action === "start") {
  //           if (element.status !== "running") {
  //             setTimeout(() => {
  //               pollForStatus(key, name, action, attempt);
  //             }, 2000);
  //           }
  //         } else if (action === "stop") {
  //           if (element.status !== "stopped") {
  //             setTimeout(() => {
  //               pollForStatus(key, name, action, attempt);
  //             }, 2000);
  //           }
  //         }
  //       }
  //     });
  //   }
  // };

  function renderServerCardList() {
    const serverListRendered = [];
    if (isDebug) {
      const servers = [
        {
          name: "name",
          status: "status",
          ip: "ip",
          port: "port",
        },
      ];
      servers.forEach((element) => {
        serverListRendered.push(renderServerCard(element));
      });
    } else {
      serverList.forEach((element) => {
        serverListRendered.push(renderServerCard(element));
      });
    }
    return <Container>{serverListRendered}</Container>;
  }

  function renderServerCard(data) {
    return (
      <Card color={data.ip !== "" ? "primary" : "secondary"}>
        <CardHeader color="primary">{data.name}</CardHeader>
        <CardBody>
          <CardText>{data.status}</CardText>
          <Button
            onClick={() =>
              startStopServer(
                "start",
                data.name,
                `Started server: ${data.name}`
              )
            }
            color="primary"
          >
            Start
          </Button>
          <Button
            onClick={() =>
              startStopServer("stop", data.name, `Stopped server: ${data.name}`)
            }
            color="primary"
          >
            Stop
          </Button>
          <Button
            onClick={() =>
              startStopServer("list", data.name, `Refreshed server status.`)
            }
            color="primary"
          >
            {actionStatus}
          </Button>
          {renderServerCardStatus(data)}
        </CardBody>
      </Card>
    );
  }

  function renderServerCardStatus(data) {
    if (data.ip !== "") {
      const ip = data.ip + (data.port === "" ? "" : `:${data.port}`);
      return (
        <InputGroup>
          <Input readOnly value={ip} id={data.name} />
          <InputGroupAddon addonType="append">
            <Button
              onClick={() => {
                copyToClipboard(data.name);
              }}
              color="primary"
            >
              Copy
            </Button>
          </InputGroupAddon>
        </InputGroup>
      );
    }
  }

  function copyToClipboard(id) {
    var copyText = document.getElementById(id);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    onAlertEnable("IP address copied!");
  }

  return (
    <div className="App">
      <Alert
        color="info"
        isOpen={alertVisible}
        toggle={onAlertDismiss}
        className="position-absolute"
      >
        {alertMessage}
      </Alert>
      <Container>
        <Jumbotron>
          <Row>
            <Col md="6" className="logo">
              <h1>Start/Stop Craztad Game Servers</h1>
              <p>Enter the password I gave you, it is like 200 chars long.</p>
              <p>
                I rate limited this thing a lot so don't mess up a lot or it
                will lock you out.
              </p>
            </Col>
            <Col md="6">
              <InputGroup>
                <InputGroupAddon addonType="append">
                  <Button onClick={listServers} color="primary">
                    {actionStatus}
                  </Button>
                </InputGroupAddon>
                <Input placeholder="password" type="password" id="key_input" />
              </InputGroup>
              <Form>
                <FormGroup>{renderServerCardList()}</FormGroup>
              </Form>
            </Col>
          </Row>
        </Jumbotron>
      </Container>
    </div>
  );
}

export default App;
